import { Link } from '@mui/material';
import React from 'react';

import styles from './ExternalLink.module.css';

const ExternalLink = ({ to = '', target = '_blank', label = '' }) => {
  return (
    <Link
      className={styles.credits}
      component="button"
      variant="body2"
      onClick={() => {
        window.open(to, target).focus();
      }}
    >
      {label}
    </Link>
  );
};

export default ExternalLink;
