import { useState, useEffect } from 'react';
import { EnhancedTable } from '@/components/Table';
import { getHighVolumeDailyBars, getStockData } from '@/lib/stock';
import {
  calculateGapup,
  calculateMorningGapup,
  processDailyBarData,
  timeHHmm,
} from '@/lib/utils';
import { stockFieldsConfig, stockFieldsOrder } from 'config/fields';
import styles from './History.module.css';
import { Box, IconButton, Tooltip } from '@mui/material';
import { StackedLineChart } from '@mui/icons-material';
import { Dialog } from '@/components/Dialog';
import { COLORS, TIMEFRAMES } from '@/lib/utils/constants';
import MultiLineChart from '@/components/Chart/MultiLineTimeseries';

const History = ({ onRowClick = () => {}, symbol }) => {
  const [historyConfig, setHistoryConfig] = useState({});
  const [showAggregatedChart, setShowAggregatedChart] = useState(false);
  const [multiChartData, setMultiChartData] = useState([]);

  const onCloseChartModal = () => {
    setShowAggregatedChart(false);
  };

  const onOpenChartModal = () => {
    setShowAggregatedChart(true);
  };

  const processMultiChartData = (data) => {
    const processedData = {};

    Object.keys(data).forEach((date) => {
      const _data = data[date];

      const barAt930EST = _data.find(({ Timestamp }) =>
        Timestamp.includes('T09:30:00')
      );

      const basePriceToCalcualtePercentage = barAt930EST
        ? barAt930EST.OpenPrice
        : _data[0].OpenPrice;

      processedData[date] = _data.map((d) => ({
        ...d,
        timestampInHHmm: timeHHmm(d.Timestamp),
        openPriceByPercentage: (
          ((d.OpenPrice - basePriceToCalcualtePercentage) /
            basePriceToCalcualtePercentage) *
          100
        ).toFixed(2),
      }));
    });

    return processedData;
  };

  const onVisualizeClick = async () => {
    const dates = historyConfig.data
      .sort((a, b) => b.Volume - a.Volume)
      .slice(0, 10)
      .map((d) => d.TimeStamp);
    const res = await getStockData({
      symbol,
      dates,
      timeframe: TIMEFRAMES.MINS_5,
    });

    const processedMultiChartData = processMultiChartData(res);
    setMultiChartData(processedMultiChartData);

    onOpenChartModal();
  };

  const fetchHistory = async (symbol) => {
    try {
      const data = await getHighVolumeDailyBars(symbol);
      const { dailyData } = processDailyBarData(data);
      setHistoryConfig({
        data: dailyData
          .map((bar) => ({
            Symbol: symbol,
            TimeStamp: bar.Timestamp,
            gapUp: calculateGapup(bar),
            morningGapUp: calculateMorningGapup(bar),
            OpenPrice: bar.OpenPrice,
            HighPrice: bar.HighPrice,
            LowPrice: bar.LowPrice,
            ClosePrice: bar.ClosePrice,
            Volume: bar.Volume,
            PreviousClose: bar.PreviousClose,
          }))
          .sort(
            (a, b) =>
              new Date(b.TimeStamp).getTime() - new Date(a.TimeStamp).getTime()
          ),
        columns: stockFieldsOrder.map((field) => ({
          ...(stockFieldsConfig[field] || {}),
          disablePadding: true,
        })),
        rowBackgroundColor: (row) => {
          const { OpenPrice, ClosePrice } = row;
          return OpenPrice > ClosePrice
            ? COLORS.BACKGROUND_RED
            : COLORS.BACKGROUND_GREEN;
        },
      });
    } catch (err) {
      setHistoryConfig({});
      console.log('Error fetching history', err);
    }
  };
  useEffect(() => {
    if (symbol) {
      fetchHistory(symbol);
    }
  }, [symbol]);

  return (
    <Box className={styles.container}>
      <Box className={styles.actionsContainer}>
        <Tooltip title="Visualizes data as a percentage compared to market open price for the top 10 most active days">
          <IconButton onClick={onVisualizeClick}>
            <StackedLineChart />
          </IconButton>
        </Tooltip>
      </Box>
      <Box className={styles.tableContainer}>
        <EnhancedTable config={historyConfig} onRowClick={onRowClick} />
      </Box>
      <Box>
        <Dialog
          onClose={onCloseChartModal}
          open={showAggregatedChart}
          title={`Visualizing historical data - ${symbol}`}
          maxWidth="xl"
          fullScreen={true}
        >
          <MultiLineChart data={multiChartData} />
        </Dialog>
      </Box>
    </Box>
  );
};

export default History;
