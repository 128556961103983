import React, { useEffect, useState } from 'react';
import { Box, Button, TextField } from '@mui/material';

const StockEntry = ({
  onStockEntryClick = () => {},
  fetchFilingsSummary = () => {},
  defaultSymbol = '',
}) => {
  const [symbol, setSymbol] = useState(defaultSymbol);
  const _onClick = () => {
    onStockEntryClick(symbol);
  };

  const _onChange = (e) => {
    setSymbol(e.target.value?.toUpperCase());
  };

  const _onKeyUp = (e) => {
    if (e.keyCode == 13) {
      onStockEntryClick(symbol);
    }
  };

  useEffect(() => {
    setSymbol(defaultSymbol);
  }, [defaultSymbol]);

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '0.5rem',
        placeItems: 'center',
        margin: '0.5rem',
      }}
    >
      <TextField
        label="Symbol"
        size="small"
        onChange={_onChange}
        onKeyUp={_onKeyUp}
        value={symbol}
      />
      <Button variant="contained" sx={{ height: '2.5rem' }} onClick={_onClick}>
        Go
      </Button>
      <Button
        variant="outlined"
        sx={{ height: '2.5rem' }}
        onClick={() => fetchFilingsSummary(symbol)}
      >
        Filings insights
      </Button>
    </Box>
  );
};

export default StockEntry;
