import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  TimeScale,
} from 'chart.js';
import { dateYYYYMMDD } from '@/lib/utils';
import { CHART_COLORS } from '@/lib/utils/constants';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  TimeScale
);

const MultiLineChart = ({ data: multilineData = {} }) => {
  const data = {
    datasets: Object.keys(multilineData).map((date, idx) => {
      const _data = multilineData[date];

      return {
        label: dateYYYYMMDD(date),
        data: _data.map((d) => ({
          x: d.timestampInHHmm,
          y: d.openPriceByPercentage,
        })),
        borderColor: CHART_COLORS[idx % 10],
        backgroundColor: CHART_COLORS[idx % 10],
      };
    }),
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Multi-Line Time Series Chart',
      },
    },
    borderWidth: 1,
    pointRadius: 0,
    scales: {
      x: {
        type: 'time',
        time: {
          parser: 'HH:mm', // Input format
          unit: 'minute', // Scale unit
          displayFormats: {
            minute: 'HH:mm', // Display format
          },
          tooltipFormat: 'yyyy-MM-dd HH:mm', // Tooltip format
        },
        // },
        title: {
          display: true,
          text: 'Time (5 minute intervals)',
        },
        ticks: {
          stepSize: 5,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Price in %(compared to Market open price)',
        },
      },
    },
  };

  return <Line data={data} options={options} />;
};

export default MultiLineChart;
