export const playSwipe = (Audio) => {
  const swipe = new Audio('sounds/swipe.mp3');
  swipe.play();
};

export const playPristine = (Audio) => {
  const pristine = new Audio('sounds/pristine.mp3');
  pristine.play();
};

export const playFlush = (Audio) => {
  const flush = new Audio('sounds/flush.mp3');
  flush.play();
};

export const playSwiftly = (Audio) => {
  const swiftly = new Audio('sounds/swiftly.mp3');
  swiftly.play();
};
