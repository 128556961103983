import { useEffect, useRef, useState } from 'react';
import { EnhancedTable } from '@/components/Table';
import { stockFieldsConfig } from 'config/fields';
import { playPristine } from '@/lib/utils/play';

const RealTimeHalts = ({ onRowClick = () => {} }) => {
  const [config, setConfig] = useState([]);
  const existingIndices = useRef([]);
  const itemsAlreadyClicked = useRef([]);

  const _onRowClick = (row, index) => {
    itemsAlreadyClicked.current = [...itemsAlreadyClicked.current, row.index];
    onRowClick(row, index);
    setConfig((_config) => ({
      ..._config,
      data: _config.data.map((ele) => ({
        ...ele,
        isNotClicked: !itemsAlreadyClicked.current.includes(ele.index),
      })),
    }));
  };

  useEffect(() => {
    const realTimeHaltsSSE = new EventSource(`/api/real-time/halts`);

    realTimeHaltsSSE.onmessage = (e) => {
      const newIndices = JSON.parse(e.data || '[]')
        .filter((ele) => ['LUDP', 'M'].includes(ele.reasonCode))
        .map(
          (ele) =>
            `${ele.symbol}-${ele.haltTime}${
              ele.resumptionTime ? '-' + ele.resumptionTime : ''
            }`
        );

      setConfig({
        data: JSON.parse(e.data || '[]')
          .filter((ele) => ['LUDP', 'M'].includes(ele.reasonCode))
          .map((ele) => {
            const index = `${ele.symbol}-${ele.haltTime}${
              ele.resumptionTime ? '-' + ele.resumptionTime : ''
            }`;
            return {
              ...ele,
              Symbol: ele.symbol,
              HaltTime: ele.haltTime,
              ResumptionTime: ele.resumptionTime,
              index: index,
              isNotClicked:
                itemsAlreadyClicked.current?.length &&
                !itemsAlreadyClicked.current.includes(index),
            };
          }),
        columns: [
          stockFieldsConfig.issueSymbol,
          stockFieldsConfig.haltTime,
          stockFieldsConfig.resumptionTime,
          stockFieldsConfig.reasonCode,
        ],
      });

      //Check for new entries
      let newEntriesExist = false;

      newIndices.forEach((idx) => {
        if (newIndices?.length && !existingIndices.current.includes(idx)) {
          newEntriesExist = true;
        }
      });

      existingIndices.current = newIndices;

      if (newEntriesExist) {
        playPristine(Audio);
      }
    };

    realTimeHaltsSSE.onerror = () => {
      // error log here
      realTimeHaltsSSE.close();
    };

    return () => {
      realTimeHaltsSSE.close();
    };
  }, []);

  return (
    <EnhancedTable
      heading="Halts"
      config={config}
      onRowClick={_onRowClick}
      highlightItems={false}
    />
  );
};

export default RealTimeHalts;
